import React, { useState, useEffect } from "react";

const groupByFoodType = (foods) => {
  const groupedFoods = {};
  foods.reverse().forEach((food) => {
    const { type } = food;
    if (!groupedFoods[type.name]) {
      groupedFoods[type.name] = [];
    }
    groupedFoods[type.name].push(food);
  });
  return groupedFoods;
};

const categoryArr = [
  "特製養生雞湯",
  "麵飯類",
  "湯品",
  "炸物",
  "熱炒",
  "海產類",
  "土雞類",
  "泡茶類",
  "酒類、飲料",
];

const Menu = () => {
  const [hachaFoods, setHachaFoods] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});

  useEffect(() => {
    fetch("/api/hachafoods")
      .then((res) => res.json())
      .then((data) => setHachaFoods(data))
      .catch((err) => console.log(err));
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategories((prevSelected) => ({
      ...prevSelected,
      [category]: !prevSelected[category], // 切換選中狀態
    }));
  };

  const filteredFoods = hachaFoods.filter(
    (food) => !selectedCategories[food.type.name]
  );

  const groupedFoods = groupByFoodType(filteredFoods);

  return (
    <section className="menu_wrapper">
      <section className="menu_select_block">
        <div className="attention">
          <a href="https://www.facebook.com/profile.php?id=100063732890876">
            <h3>菜單品項繁多</h3>
            <p>當日供應品項及售價請以實際店面為主</p>
          </a>
        </div>
        <ul className="menu_tabs">
          {categoryArr.map((category) => (
            <li
              key={category}
              className={selectedCategories[category] ? "minus" : ""}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </li>
          ))}
        </ul>
      </section>

      <section className="menu-content">
        {Object.entries(groupedFoods).map(([foodType, foods]) => (
          <div className="food-type-container" key={foodType}>
            <h3 className="food-type">{foodType}</h3>
            {foods.map((food) => (
              <div className="menu-item noRow" key={food.id}>
                <h4>{food.name}</h4>
                <div>
                  <p>{food.priceA}</p>
                  <p>{food.priceB}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </section>
    </section>
  );
};

export default Menu;
