import React from 'react'

const Store = () => {
  return (
    <section className="construct_wrapper">
        各類極品咖啡豆、茶葉，歡營蒞臨選購...(建置中)
    </section>
  )
}

export default Store